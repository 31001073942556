import React, { useState } from 'react'; 
import { useNavigate } from 'react-router';
import { CgClose } from 'react-icons/cg';
import { SERVER_URL } from '../../App';

interface DispatchModalProps {
  email: string,
  boxType: string,
  closeModal: Function,
  updatePage: Function,
}

function DispatchOrderModal(props:DispatchModalProps) {
  const [boxID, setBoxID] = useState("");
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [errorMessage, setErrormessage] = useState("");
  const navigate = useNavigate();

  // Function to send request to update the order as dispatched
  function MarkAsDispatched() {
    const url = SERVER_URL + "/stock_management/setcurrentorderasdispatched";

    // Check all chechboxes are checked
    if (!check1 || !check2 || !check3) {
      setErrormessage("All checks must be ticked.");
      return false;
    }

    // Check if box ID isn't empty
    if (boxID==="") {
      setErrormessage("BoxID must be given.");
      return false;
    }

    // Send request
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        email: props.email,
        boxType: props.boxType,
        boxID: boxID,
      })
    })
      .then((res) => {
        const data:any = res.json();
  
        // If request is successful
        if (res.status === 200) {
          props.updatePage()
        
        // If token has expired, redirect to the login page
        } else if (res.status === 401) {
          navigate("/");

        // If any other error occurs
        } else {
          return data;
        }
      })
        .then((data) => {
          setErrormessage(data["error"]["message"]);
        })
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>Ready to dispatch?</h2>
          <CgClose size={26} style={{marginRight: 5}} onClick={() => {props.closeModal()}} />
        </div>
        <div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="mce-CNAME"
              onChange={(event) => setCheck1(!check1)}
              checked={check1}
              >
            </input>
            <h3>Confirm label information is correct.</h3>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="mce-CNAME"
              onChange={(event) => setCheck2(!check2)}
              checked={check2}
              >
            </input>
            <h3>Confirm box contains correct tools, paperwork, & consumables.</h3>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="mce-CNAME"
              onChange={(event) => setCheck3(!check3)}
              checked={check3}
              >
            </input>
            <h3>Confirm gadget has correct fault.</h3>
          </div>
        </div>

        <input
          type="text"
          name="CNAME"
          placeholder="Box ID"
          id="mce-CNAME"
          className="text-input"
          onChange={(event) => setBoxID(event.target.value)}
          value={boxID}
        />
        <div className="modal-footer">
          <p className="error-txt">{errorMessage}</p>
          <div className="button" onClick={() => MarkAsDispatched()}>Confirm Dispatch</div>
        </div>
      </div>
    </div>
  );
}

export default DispatchOrderModal;