import React from 'react'; 
import {useState} from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../../App';

interface ErroredOrderCardProps {
  boxType: string,
  parentsName: string,
  dispatchDays: string,
  address: string,
  postcode: string,
  email: string,
  phone: string,
  childsName: string,
  updatePage: Function,
  boxID: string
}

function ErroredOrderCard(props:ErroredOrderCardProps) {
  // State controlling wether card is expanded, showing user's details and controls
  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Update the colour and value in the "Due in"/"Overdue" tag
  let card_indicator;
  if (parseInt(props.dispatchDays) > 1) {
    card_indicator = <div className="card-indicator green">Due in {props.dispatchDays} days</div>;
  } else if (parseInt(props.dispatchDays) === 1) {
    card_indicator = <div className="card-indicator green">Due Tomorrow</div>;
  } else if (parseInt(props.dispatchDays) === 0) {
    card_indicator = <div className="card-indicator amber">Due Today</div>;
  } else if (parseInt(props.dispatchDays) < 0) {
    card_indicator = <div className="card-indicator red">{Math.abs(parseInt(props.dispatchDays))} Days Overdue</div>;
  }

  // Function to attempt to reallocate a box to the user
  function ReallocateBox() {
    const url = SERVER_URL + "/stock_management/reallocatebox";

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        email: props.email,
      })
    })
        .then((res) => {
          const data:any = res.json();
          
          // If request is successful
          if (res.ok) {
            props.updatePage();

            // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

          } else {
            return data;
          }
        })
        .then((data) => {
          setErrorMessage(data["Error"]);
      });
  }

  return (
    <div className="card-container">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <FiChevronDown size={26} style={{marginRight: 5}} />
        <h3>{props.email}</h3>
        {card_indicator}
      </div>
      <div className="card-body" hidden={!(expanded)}>
        <p>Parent's Name: {props.parentsName}</p>
        <p>Address: {props.address}</p>
        <p>Postcode: {props.postcode}</p>
        <p>Email: {props.email}</p>
        <p>Phone: {props.phone}</p>
        <p>Child's Name: {props.childsName}</p>
        <div className="modal-footer">
          <p className="error-txt">{errorMessage}</p>
          <div className="button" onClick={() => ReallocateBox()}>Allocate Box</div>
        </div>
      </div>
      <p>{errorMessage}</p>
    </div>
  );
}

export default ErroredOrderCard;