import React, { useState, useEffect } from 'react'; 
import { useNavigate } from "react-router-dom";
import UserDataRenderer from '../atoms/userDataRenderer';
import UpdateUserDataModal from '../atoms/updateUserDataModal';
import '../../App.css';
import { SERVER_URL } from '../../App';

interface SearchUsersProbs {
  updatePage: Function
}

function SearchUsersModule({updatePage}:SearchUsersProbs) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [controlsHidden, setControlsHidden] = useState(true);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [json, setJSON] = useState({
      users_child_details: {
        child_name: "",
        child_age: 0,
        icon: "",
        shipments: [],
        current_box_index: 0,
        logbooks: [],
      },
      users_guardian_details: {
        postcode: "",
        address: "",
        phone: "",
        referral_code: "",
      },
      users_instance: {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        date_joined: "",
    }
    });

  // If user is not logged in, return to login page
  const navigate = useNavigate()
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []); 

  function getUser() {
    const url = SERVER_URL + "/stock_management/getuser";

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        email: email,
      })
    })
        .then((res) => {
          const data:any = res.json();

          // If token has expired, redirect to the login page
          if (res.status === 401) {
            navigate("/");
          } else {
            return data;
          }
        })
        .then((data) => {
          // If request was successful
          if (data["error"] == null) {
            setJSON(data);
            setControlsHidden(false);
          } else {
            setErrorMessage(data["error"]["message"]);
          }
        });
  }

  return (
    <div className="columns-container">
      <div className="third-column">
        <h2>Search Users by Email</h2>
        <div className="search-window">
          <input
            type="text"
            name="CNAME"
            placeholder="Email"
            id="mce-CNAME"
            className="text-input"
            style={{width: "100%"}}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <div className="modal-footer">
            <p className="error-txt">{errorMessage}</p>
            <div className="button" onClick={() => getUser()}>Search</div>
          </div>
        </div>
      </div>
      <div className="twothird-column">
        <UserDataRenderer json={json} hidden={!controlsHidden}/>
        <div className="column-footer" hidden={controlsHidden}> 
          <div className="button" onClick={() => setModalVisibility(true)}>Edit User Data</div>
        </div>
      </div>
      <div hidden={!modalVisibility}>
        <UpdateUserDataModal closeModal={() => setModalVisibility(false)} updatePage={() => {updatePage(); setModalVisibility(false); getUser();}} userData={json} original_email={email}/>
      </div>
    </div>
  );
}

export default SearchUsersModule;