import React, { useState } from 'react'; 
import { RiCheckboxMultipleLine, RiAddBoxLine, RiRefreshLine } from 'react-icons/ri';
import UpdateBoxByIDModal from '../atoms/updateBoxByIDModal';
import AddNewBoxModal from '../atoms/addNewBoxModal';

interface UpdateTransactionsByIDCardProps {
  updatePage: Function
}

function UpdateTransactionsButtons(props:UpdateTransactionsByIDCardProps) {
  const [modal1Visibility, setModal1Visibility] = useState(false);
  const [modal2Visibility, setModal2Visibility] = useState(false);

  return (
    <div className='button-card-cont'>
      <div className="pill-button" onClick={() => props.updatePage()}>
        <RiRefreshLine size={18}/>
        <h3>Refresh</h3>
      </div>
    </div>
  );
}

export default UpdateTransactionsButtons;