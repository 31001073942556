import React from 'react';
import Navbar from '../components/organisms/navbar';
import SearchUsersModule from '../components/molecules/searchUsersModule';
import '../App.css';

function UsersDashboard() {
  return (
    <div className="app-container">
      <Navbar />
      <SearchUsersModule updatePage={() => {console.log(" ")}}/>
    </div>
  );
}

export default UsersDashboard;