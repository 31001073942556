import React, { useState } from 'react'; 
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router';

interface LostBoxProps {
  email: string,
  boxType: string,
  closeModal: Function,
  updatePage: Function,
  boxID: string
}

function LostBoxModal(props:LostBoxProps) {
  const [errorMessage, setErrormessage] = useState("");
  const navigate = useNavigate();

  // function to update order as returned
  function MarkAsReturned(condition:string) {
    const url = process.env.REACT_APP_SERVER_URL + "/stock_management/setcurrentorderaslost";

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        email: props.email,
        boxType: props.boxType,
        boxID: props.boxID,
        condition: condition,
        returnByID: false
      })
    })
        .then((res) => {
          const data:any = res.json();

          // If request is successful
          if (res.status === 200) {
            props.closeModal();
            props.updatePage();
          
          // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

          // If an error occurs, log it to console
          } else {
            return data;
          }    
        })
        .then((data) => {
          setErrormessage(data["error"]["message"]);
      });
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>Are you sure this box is lost?</h2>
          <CgClose size={26} style={{marginRight: 5}} onClick={() => {props.closeModal()}} />
        </div>
        <h3>When a box is lost, it's data is kept, but it's ignored by the system. There is no easy way to unmark it as lost.</h3>
        <div className="modal-footer">
          <p className="error-txt">{errorMessage}</p>
          <div className="button red" onClick={() => MarkAsReturned("Missing Items")}>Mark as Lost</div>
        </div>
      </div>
    </div>
  );
}

export default LostBoxModal;