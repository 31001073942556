import TransactionsCard from "../molecules/transactionsCard";


interface TransactionsProps {
  data: any;
  updatePage: Function;
}

function TransactionsModule(props: TransactionsProps) {
  let cards = [];
  for (const transaction of props.data) {
    cards.push(
      <TransactionsCard
        key={transaction["id"]}
        id={transaction['id']}
        status={transaction['order_status']}
        user={transaction['user']}
        preUser={transaction['pre_user']}
        stripeId={transaction['stripe_transaction_id']}
        date={transaction['payment_date']}
        numKits={transaction['kits_purchased']}
        discountCodeUsed={transaction['discount_code_used']}
      />
    );
  }

  return <div className="card-list-container">{cards}</div>;
}

export default TransactionsModule;
