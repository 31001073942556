import React, { useState, useEffect } from 'react'; 
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../../App';


interface EditUserDataModalProps {
  closeModal: Function,
  updatePage: Function,
  original_email: string,
  userData: {
    users_child_details: {
      child_name: string;
      icon: string;
      shipments: any[];
      current_box_index: number;
      logbooks: any[];
    };
    users_guardian_details: {
      postcode: string;
      address: string;
      phone: string;
    };
    users_instance: {
      email: string;
      password: string;
      first_name: string;
      last_name: string;
      date_joined: string;
    };
}
}

function UpdateUserDataModal(props:EditUserDataModalProps) {
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState(props.userData.users_instance.email);
    const [firstname, setFirstname] = useState(props.userData.users_instance.first_name);
    const [lastname, setLastname] = useState(props.userData.users_instance.last_name);
    const [childsName, setChildsName] = useState(props.userData.users_child_details.child_name);
    const [phone, setPhone] = useState(props.userData.users_guardian_details.phone);
    const [address, setAddress] = useState(props.userData.users_guardian_details.address);
    const [postcode, setPostcode] = useState(props.userData.users_guardian_details.postcode);
    const navigate = useNavigate();

    // Prefill all of the fields with the user's existing data
    useEffect(() => {
        setEmail(props.userData.users_instance.email);
        setFirstname(props.userData.users_instance.first_name);
        setLastname(props.userData.users_instance.last_name);
        setChildsName(props.userData.users_child_details.child_name);
        setPhone(props.userData.users_guardian_details.phone);
        setAddress(props.userData.users_guardian_details.address);
        setPostcode(props.userData.users_guardian_details.postcode);
      }, [props.userData]); 

    // Function to push new user data to the database
    function UpdateUser() {
    const url = SERVER_URL + "/stock_management/updateuserdata";

    fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
        },
        body: JSON.stringify({
            original_email: props.original_email,
            email: email,
            first_name: firstname,
            last_name: lastname,
            postcode: postcode,
            address: address,
            phone: phone,
            child_name: childsName,
        })
    })
        .then((res) => {
          const data:any = res.json();
            
          // If request is successful
          if (res.status === 200) {
            props.updatePage();
               
          // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

          // If any error occurs
          } else {
            return data;
          }
        })
        .then((data) => {
          setErrorMessage(data["error"]["message"]);
        });
    }

    return (
        <div className="modal-container">
        <div className="modal">
          <div className="modal-header">
            <h2>Edit User Data</h2>
            <CgClose size={26} style={{marginRight: 5}} onClick={() => {props.closeModal()}} />
          </div>

          <div className="forms-list">
            <input
                type="text"
                name="CNAME"
                placeholder="Email"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setEmail(event.target.value)}
                value={email}
            />
            <input
                type="text"
                name="CNAME"
                placeholder="First Name"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setFirstname(event.target.value)}
                value={firstname}
            />
            <input
                type="text"
                name="CNAME"
                placeholder="Last Name"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setLastname(event.target.value)}
                value={lastname}
            />
            <input
                type="text"
                name="CNAME"
                placeholder="Child's Name"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setChildsName(event.target.value)}
                value={childsName}
            />
            <input
                type="text"
                name="CNAME"
                placeholder="Phone"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
            />
            <input
                type="text"
                name="CNAME"
                placeholder="Address"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setAddress(event.target.value)}
                value={address}
            />
            <input
                type="text"
                name="CNAME"
                placeholder="Postcode"
                id="mce-CNAME"
                className="text-input"
                style={{width: "100%"}}
                onChange={(event) => setPostcode(event.target.value)}
                value={postcode}
            />
          </div>


          <div className="modal-footer">
            <p className="error-txt">{errorMessage}</p>
            <div className="button" onClick={() => UpdateUser()}>Update</div>
          </div>
        </div>
      </div>
    )
}

export default UpdateUserDataModal;