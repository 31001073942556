import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';
import OrdersDashboard from './pages/ordersDashboard';
import StockDashboard from './pages/stockDashboard';
import UsersDashboard from './pages/usersDashboard';
import Login from './pages/login';
import TransactionsDashboard from './pages/transactionsDashboard';

export const SERVER_URL: string = "https://api.teamrepair.dev"
// export const SERVER_URL: string = "https://dev-api.teamrepair.dev"
// export const SERVER_URL: string = "http://localhost:8000"

function App() {
  return (
    <div className="app-container">
    <Router>
      <Routes>
        <Route path="/orders" element={<OrdersDashboard />} />
        <Route path="/stock" element={<StockDashboard />} />
        <Route path="/users" element={<UsersDashboard />} />
        <Route path="/transactions" element={<TransactionsDashboard />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  </div>
  );
}

export default App;
