import React, {useEffect} from 'react'; 
import { useNavigate } from "react-router-dom";
import { HiOutlineLogout } from 'react-icons/hi';
import { SERVER_URL } from '../../App';


function Navbar() {

    // If user is not logged in, return to login page
    const navigate = useNavigate()
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate("/");
      }
    }, []); 

    // Function to clear the token, and redirect to the login page
    function logout() {
      localStorage.token = null;
      navigate("/");
    }
  
  return (
    <div className="navbar-cont">
      <img src="logo.webp" alt={"Logo"} className={"logo-image"}/>
      <p className="link"><a href="/orders">Orders</a></p>
      <p className="link"><a href="/stock">Stock</a></p>
      <p className="link"><a href="/users">Users</a></p>
      <p className="link"><a href="/transactions">Transactions</a></p>
      <p style={{marginLeft:40, fontWeight:700, color:"red"}}>{SERVER_URL === "https://api.teamrepair.dev"? "" : SERVER_URL === "https://dev-api.teamrepair.dev" ? "STAGING BACKEND" : "LOCAL BACKEND" }</p>
      <div className="pill-button red" onClick={() => logout()}>
        <HiOutlineLogout size={18}/>
        <h3>Logout</h3>
      </div>
    </div>
  );
}

export default Navbar;