import React from 'react'; 
import '../../App.css';
import BoxCard from '../molecules/boxCard';

interface AllBoxesProps {
  boxData: any
  updatePage: Function
}

function AvaliabilityByBoxModule({boxData, updatePage}:AllBoxesProps) {
  let cards = [];
  for (const box of Object.keys(boxData["SortedBoxes"])) {
    cards.push(<BoxCard boxData={boxData["SortedBoxes"][box]} type={box} allocatable={boxData["AllocatableBoxes"]} updatePage={() => {updatePage()}}/>);
  } 
  return (
    <div style={{width:"100%"}}>
      <h2>Avaliable boxes by type</h2>
      <div className="titles-cont">
        {cards}
      </div>
    </div>
  );
}

export default AvaliabilityByBoxModule;