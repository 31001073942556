import React, { useState } from 'react'; 
import { RiCheckboxMultipleLine, RiAddBoxLine, RiRefreshLine } from 'react-icons/ri';
import UpdateBoxByIDModal from '../atoms/updateBoxByIDModal';
import AddNewBoxModal from '../atoms/addNewBoxModal';

interface UpdateBoxByIDCardProps {
  updatePage: Function
}

function UpdateBoxButtons(props:UpdateBoxByIDCardProps) {
  const [modal1Visibility, setModal1Visibility] = useState(false);
  const [modal2Visibility, setModal2Visibility] = useState(false);

  return (
    <div className='button-card-cont'>
      <div className="pill-button" onClick={() => setModal1Visibility(!modal1Visibility)}>
        <RiCheckboxMultipleLine size={18}/>
        <h3>Update Box Status by ID</h3>
      </div>
      <div className="pill-button stack" onClick={() => setModal2Visibility(!modal2Visibility)}>
        <RiAddBoxLine size={18}/>
        <h3>Add new box</h3>
      </div>
      <div className="pill-button stack" onClick={() => props.updatePage()}>
        <RiRefreshLine size={18}/>
        <h3>Refresh</h3>
      </div>
      <div hidden={!modal1Visibility}>
        <UpdateBoxByIDModal closeModal={() => setModal1Visibility(!modal1Visibility)} updatePage={() => props.updatePage()}/>
      </div>
      <div hidden={!modal2Visibility}>
        <AddNewBoxModal closeModal={() => setModal2Visibility(!modal2Visibility)} updatePage={() => props.updatePage()}/>
      </div>
    </div>
  );
}

export default UpdateBoxButtons;