import React, { useEffect } from "react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ReturnOrderModal from "../atoms/returnOrderModal";
import LostBoxModal from "../atoms/lostBoxModal";
import { FaCopy } from "react-icons/fa";

interface TransactionsCardProps {
  id: number;
  status: number;
  user: string;
  preUser: string;
  stripeId: string;
  date: string;
  numKits: number;
  discountCodeUsed: string;
}

function TransactionsCard(props: TransactionsCardProps) {
  const [expanded, setExpanded] = useState(false);
  const [returnModalVisibility, setReturnModalVisibility] = useState(false);
  const [lostModalVisibility, setLostModalVisibility] = useState(false);

  const orderStatusChoices = [
    "created",
    "abandoned",
    "paid",
    "cancellation_requested",
    "refunded",
  ];

  const card_indicator = (
    <div
      className={`card-indicator ${
        [1, 3].includes(props.status)
          ? "red"
          : [0, 4].includes(props.status)
          ? "amber"
          : "green"
      }`}
    >
      {orderStatusChoices[props.status]}
    </div>
  );

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(props.stripeId);
    setIsCopied(true);
  };

  useEffect(()=>{
    setTimeout(()=>{setIsCopied(false)},5000)
  }, [isCopied])

  return (
    <div className="card-container">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <FiChevronDown size={26} style={{ marginRight: 5 }} />
        <h3>{props.id} - {props.preUser}{props.user}{(!props.user && !props.preUser) && "No User"}</h3>
        {card_indicator}
      </div>
      <div className="card-body" hidden={!expanded}>
        {props.user ? (
          <p>User: {props.user}</p>
        ) : props.preUser ? (
          <p>Pre-user: {props.preUser}</p>
        ) : (
          <p>No User</p>
        )}
        {props.stripeId && (
          <div>
            <p>
              {props.stripeId.slice(0, 25)}...
              
              <button className="inline-pill-button" onClick={handleCopyClick}>
                <FaCopy /> {isCopied ? "Copied to clipboard!" : "Copy"}
              </button>
            </p>
          </div>
        )}
        <p>Payment Date/Time: {props.date}</p>
        <p>Number of kits: {props.numKits}</p>
        <p>Discount Code: {props.discountCodeUsed}</p>
      </div>
    </div>
  );
}

export default TransactionsCard;
