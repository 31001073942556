import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../App.css';
import Navbar from '../components/organisms/navbar';
import AwaitingDispatchModule from '../components/organisms/awaitingDispatchModule';
import AwaitingReturnModule from '../components/organisms/awaitingReturnModule';
import WithCustomersModule from '../components/organisms/withCustomersModule';
import OrdersButtons from '../components/molecules/ordersButtons';
import { SERVER_URL } from '../App';
import TransactionsModule from '../components/organisms/transactionsModule';
import PrePurchaseModule from '../components/organisms/prePurchaseModule';
import UpdateTransactionsButtons from '../components/molecules/updateTransactionsButtons copy';

function TransactionsDashboard() {
  const [data, setData] = useState({"transactions": [], "prePurchaseUsers": []});
  const navigate = useNavigate();

  // Function to fetch orders data
  function getData() {
    const url = SERVER_URL + "/stock_management/dumpalltransactions";

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
    })
        .then((res) => {
          const data:any = res.json();
          
          // If request is successful
          if (res.status === 200) {
            return data;
          
          // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

          // If an error occurs, log it to console
          } else {
            console.log(data["error"]["message"]);
          }
        })
        .then((data) => {
            // Update the box data, to render the columns
            console.log(data)
            setData(data);
        });
  }

  // Collect data on page load
  useEffect(() => {
    getData();
  }, []); 
  
  return (
    <div className="app-container">
      <Navbar />
      <div className='full-column-cont'>
        <UpdateTransactionsButtons updatePage={() => {getData()}}/>
      </div>
      <div className="columns-container">
        <div className="half-column">
          <h2>Transactions - {data["transactions"].length}</h2>
          <p style={{textAlign: "center"}}>Transactions are created when a user first clicks buy, then updated with payment status after a user pays. The ID is the order number, given to the user. The stripe ID can be used to look up the transaction in stripe.</p>
          <TransactionsModule data={data["transactions"]} updatePage={() => getData()} />
        </div>
        <div className="half-column">
          <h2>Pre-purchase users - {data["prePurchaseUsers"].length}</h2>
          <p style={{textAlign: "center"}}>Pre-purchase users are created when a user first clicks buy. They're updated with payment details after successful payment. Once a user completes their account (adding their password) they're deleted.</p>
          <PrePurchaseModule data={data["prePurchaseUsers"]} updatePage={() => getData()} />
        </div>
      </div>
  </div>
  );
}

export default TransactionsDashboard;