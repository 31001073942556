import React from "react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ReturnOrderModal from "../atoms/returnOrderModal";
import LostBoxModal from "../atoms/lostBoxModal";
import ReshipBoxModal from "../atoms/reshipOrderModal";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../App";

interface AwaitingReturnCardProps {
  boxType: string;
  parentsName: string;
  returnDays: string;
  email: string;
  updatePage: Function;
  boxID: string;
  dataLog: any;
  orderNumber: string;
  phone: string;
}

function AwaitingReturnCard(props: AwaitingReturnCardProps) {
  const [expanded, setExpanded] = useState(false);
  const [returnModalVisibility, setReturnModalVisibility] = useState(false);
  const [lostModalVisibility, setLostModalVisibility] = useState(false);
  const [reshipModalVisibility, setReshipModalVisibility] = useState(false);
  const [reminderSent, setReminderSent] = useState(false);

  const navigate = useNavigate();

  let card_indicator;
  if (parseInt(props.returnDays) > 1) {
    card_indicator = (
      <div className="card-indicator green">Due in {props.returnDays} days</div>
    );
  } else if (parseInt(props.returnDays) === 1) {
    card_indicator = <div className="card-indicator green">Due Tomorrow</div>;
  } else if (parseInt(props.returnDays) === 0) {
    card_indicator = <div className="card-indicator amber">Due Today</div>;
  } else if (parseInt(props.returnDays) < 0) {
    card_indicator = (
      <div className="card-indicator red">
        {Math.abs(parseInt(props.returnDays))} Days Overdue
      </div>
    );
  }

  console.log(props.dataLog["ORDERS_RETURNREMINDEREMAILSENT"]);

  // Function to fetch orders data
  function SendReminder() {
    const url = SERVER_URL + "/stock_management/sendreturnreminder";

    if (!reminderSent) {
      setReminderSent(true);
      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
        body: JSON.stringify({
          user_email: props.email,
        }),
      })
        .then((res) => {
          const data: any = res.json();

          // If request is successful
          if (res.status === 200) {
            return data;

            // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

            // If an error occurs, log it to console
          } else {
            console.log(data["error"]["message"]);
          }
        })
        .then((data) => {
          // Update the box data, to render the columns
          console.log(data);
        });
    }
  }

  return (
    <div className="card-container">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <FiChevronDown size={26} style={{ marginRight: 5 }} />
        <h3>
          {props.boxType} (id: {props.boxID})
        </h3>
        {card_indicator}
      </div>
      <div className="card-body" hidden={!expanded}>
        <p>Ref Number: {props.orderNumber}</p>
        <p>Parent's Name: {props.parentsName}</p>
        <p>Email: {props.email}</p>
        <p>Phone: {props.phone}</p>
        <p>
          Tutorial Status:{" "}
          {"APP_TUTORIALCOMPLETED" in props.dataLog
            ? "Complete ✅"
            : "Incomplete ❌"}
        </p>
        <p>
          Extensions Opened:{" "}
          {"APP_EXTENSIONOPENED_1" in props.dataLog ? "1, " : ""}
          {"APP_EXTENSIONOPENED_2" in props.dataLog ? "2, " : ""}
          {"APP_EXTENSIONOPENED_3" in props.dataLog ? "3, " : ""}
          {"APP_EXTENSIONOPENED_4" in props.dataLog ? "4, " : ""}
        </p>
        <p>Reminders Sent: </p>
        <div style={{ padding: 8 }}>
          {props.dataLog["ORDERS_RETURNREMINDEREMAILSENT"] &&
            props.dataLog["ORDERS_RETURNREMINDEREMAILSENT"].map(
              (time: string) => {
                return <p key={time}>{time}</p>;
              }
            )}
        </div>
        <div className="button-row">
          <div
            className="button red"
            onClick={() => setLostModalVisibility(true)}
          >
            Lost
          </div>
          <div
            className="button red left"
            onClick={() => setReshipModalVisibility(true)}
          >
            Reship
          </div>
          <div
            className="button left"
            onClick={() => setReturnModalVisibility(true)}
          >
            Returned
          </div>
          <div className="button left" onClick={() => SendReminder()}>
            {!reminderSent ? "Remind" : "✅"}
          </div>
        </div>
      </div>
      <div hidden={!returnModalVisibility}>
        <ReturnOrderModal
          email={props.email}
          boxType={props.boxType}
          closeModal={() => setReturnModalVisibility(false)}
          updatePage={() => props.updatePage()}
          boxID={props.boxID}
        />
      </div>
      <div hidden={!lostModalVisibility}>
        <LostBoxModal
          email={props.email}
          boxType={props.boxType}
          closeModal={() => setLostModalVisibility(false)}
          updatePage={() => props.updatePage()}
          boxID={props.boxID}
        />
      </div>
      <div hidden={!reshipModalVisibility}>
        <ReshipBoxModal
          email={props.email}
          boxType={props.boxType}
          closeModal={() => setReshipModalVisibility(false)}
          updatePage={() => props.updatePage()}
          boxID={props.boxID}
        />
      </div>
    </div>
  );
}

export default AwaitingReturnCard;
