import React, { useState } from 'react'; 
import { useNavigate } from 'react-router';
import { CgClose } from 'react-icons/cg';
import { SERVER_URL } from '../../App';

interface UpdateBoxModalProps {
  closeModal: Function,
  updatePage: Function,
  boxID: string,
}

function UpdateBoxModal(props:UpdateBoxModalProps) {
  const [errorMessage, setErrormessage] = useState("");
  const [status, setBoxStatus] = useState("returned");
  const navigate = useNavigate();

  // Send update request to database
  function UpdateBox() {
    const url = SERVER_URL + "/stock_management/updateboxstatus";

    // Check if ID is input
    if (props.boxID==="") {
      setErrormessage("BoxID must be given.");
      return false;
    }

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        boxID: props.boxID,
        status: status,
      })
    })
        .then((res) => {
          const data:any = res.json();
                    
          // If request is successful
          if (res.status === 200) {
            props.updatePage();
            props.closeModal();

            // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

          // If any other error occurs
          } else {
            return data;
          }
        })
        .then((data) => {
          setErrormessage(data["error"]["message"]);
      });
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>Update Boxes Status</h2>
          <CgClose size={26} style={{marginRight: 5}} onClick={() => {props.closeModal()}} />
        </div>
        <h3>If the gadget is damaged and/or any tools or spare parts are not included, mark the box as damaged.</h3>
        <select value={status} onChange={(event) => setBoxStatus(event.target.value)}>
          <option selected value="returned">Returned</option>
          <option value="damaged">Damaged</option>
          <option value="ready">Ready</option>
          <option value="institution">Institutional</option>
        </select>
        <div className="modal-footer">
          <p className="error-txt">{errorMessage}</p>
          <div className="button" onClick={() => UpdateBox()}>Update</div>
        </div>
      </div>
    </div>
  );
}

export default UpdateBoxModal;