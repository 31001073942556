import React, { useState, useEffect } from 'react'; 
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../../App';

interface AddNewBoxModalProps {
  closeModal: Function,
  updatePage: Function,
}

// List of the names of all boxes - as they are stored in the database
type BoxTypes = "console" | "rccar" | "microscope" | "tbc3" | "tbc4" | "tbc5" | "tbc6" | "tbc7" | "tbc8" | "tbc9" | "tbc10" | "tbc11";

function AddNewBoxModal(props:AddNewBoxModalProps) {
  const [type, setType] = useState<BoxTypes>("console");
  const [version, setVersion] = useState("");
  const [status, setStatus] = useState("");
  const [version_options, setVersionOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  // List of all the versions of each box - as they are stored in the database
  const box_versions = {
    "console": ["brandcollective_v1"],
    "rccar": ["porsche_v1"],
    "microscope": ["carson_v1"],
    "tbc3": [],
    "tbc4": [],
    "tbc5": [],
    "tbc6": [],
    "tbc7": [],
    "tbc8": [],
    "tbc9": [],
    "tbc10": [],
    "tbc11": [],
  };

  // Function to update the list of version options when a box type is selected
  function updateVersionOptions(type:BoxTypes) {
    setType(type);
    let version_options:any = [];
    for (let version of box_versions[type]) { 
      version_options.push(<option value={version}>{version}</option>)
    }
    setVersionOptions(version_options);
    setVersion(box_versions[type][0])
  }

  // Set default verision to console
  useEffect(() => {
    updateVersionOptions("console");
  }, []); 

  useEffect(() => {
    console.log(version);
  }, [version]); 

  // Function to push updated status to database
  function UpdateBoxStatus() {
    const url = SERVER_URL + "/stock_management/addnewbox";

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        type: type,
        version: version,
        status: status,
      })
    })
        .then((res) => {
          const data:any = res.json();

          // If token has expired, redirect to the login page
          if (res.status === 401) {
            navigate("/");
          } else {
            return data;
          }
        })
        .then((data) => {
          // If the request errored, return the error
          if (data["error"] != null) {
            setErrorMessage(data["error"]["message"]);
          
          // If the request succeeded, return its new ID
          } else {
            setSuccessMessage("New Box ID: " + data["boxID"]);
          }
      });
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>Add a New Box</h2>
          <CgClose size={26} style={{marginRight: 5}} onClick={() => {props.closeModal()}} />
        </div>
        <select value={type} onChange={(event) => {updateVersionOptions((event.target.value as BoxTypes))}}>
          <option selected value="console">Console</option>
          <option value="rccar">RC Car</option>
          <option value="microscope">Microscope</option>
          <option value="tbc3">TBC 3</option>
          <option value="tbc4">TBC 4</option>
          <option value="tbc5">TBC 5</option>
          <option value="tbc6">TBC 6</option>
          <option value="tbc7">TBC 7</option>
          <option value="tbc8">TBC 9</option>
          <option value="tbc9">TBC 9</option>
          <option value="tbc10">TBC 10</option>
          <option value="tbc11">TBC 11</option>
        </select>
        <select value={version} onChange={(event) => {setVersion(event.target.value)}}>
          {version_options}
        </select>
        <select value={status} onChange={(event) => setStatus(event.target.value)}>
          <option selected value="returned">Returned</option>
          <option value="damaged">Damaged</option>
          <option value="ready">Ready</option>
        </select>
        <div className="modal-footer">
          <p className="error-txt">{errorMessage}</p>
          <p>{successMessage}</p>
          <div className="button" onClick={() => UpdateBoxStatus()}>Add</div>
        </div>
      </div>
    </div>
  );
}

export default AddNewBoxModal;