import React from "react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import DispatchOrderModal from "../atoms/dispatchOrderModal";

interface AwaitingDispatchCardProps {
  boxType: string;
  parentsName: string;
  dispatchDays: string;
  address: string;
  postcode: string;
  email: string;
  phone: string;
  childsName: string;
  updatePage: Function;
  orderNumber: string;
  referralCode: string;
}

function AwaitingDispatchCard(props: AwaitingDispatchCardProps) {
  const [expanded, setExpanded] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);

  let card_indicator;
  if (parseInt(props.dispatchDays) === 1) {
    card_indicator = <div className="card-indicator green">Due Tomorrow</div>;
  } else if (parseInt(props.dispatchDays) === 0) {
    card_indicator = <div className="card-indicator amber">Due Today</div>;
  } else if (parseInt(props.dispatchDays) < 0) {
    card_indicator = (
      <div className="card-indicator red">
        {Math.abs(parseInt(props.dispatchDays))} Days Overdue
      </div>
    );
  }

  return (
    <div className="card-container">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <FiChevronDown size={26} style={{ marginRight: 5 }} />
        <h3>{props.boxType}</h3>
        {card_indicator}
      </div>

      <div className="card-body" hidden={!expanded}>
        <p>Ref Number: {props.orderNumber}</p>
        <p>Parent's Name: {props.parentsName}</p>
        <p>Address: {props.address.replaceAll("$", ", ")}</p>
        <p>Postcode: {props.postcode}</p>
        <p>Email: {props.email}</p>
        <p>Phone: {props.phone}</p>
        <p>Referral code: {props.referralCode}</p>
        <p>Child's Name: {props.childsName}</p>
        <div className="button" onClick={() => setModalVisibility(true)}>
          Dispatch
        </div>
      </div>
      <div hidden={!modalVisibility}>
        <DispatchOrderModal
          email={props.email}
          boxType={props.boxType}
          closeModal={() => setModalVisibility(false)}
          updatePage={() => {
            props.updatePage();
            setModalVisibility(false);
          }}
        />
      </div>
    </div>
  );
}

export default AwaitingDispatchCard;
