import React, {useState} from 'react'; 
import { FiChevronDown } from 'react-icons/fi';
import UpdateBoxModal from "../atoms/updateBoxModal";


interface AwaitingReturnProps {
  boxData: any
  allocatable: any,
  updatePage: Function
  type: string
}

function BoxCard(props:AwaitingReturnProps) {
  const [expanded, setExpanded] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [boxID, setBoxID] = useState("");


  let card_indicator;
  if (parseInt(props.allocatable[props.type]) === null) {
    card_indicator = <div className="card-indicator red">None Allocatable</div>;
  } else if (parseInt(props.allocatable[props.type]) >= 6) {
    card_indicator = <div className="card-indicator green">{props.allocatable[props.type]} Allocatable</div>;
  } else if (parseInt(props.allocatable[props.type]) <= 5) {
    card_indicator = <div className="card-indicator amber">{props.allocatable[props.type]} Allocatable</div>;
  } 

  let boxes = [];
  for (const status of Object.keys(props.boxData)) {
    for (const id of props.boxData[status]) {
      boxes.push(<div className="box-row"><p>Box ID: {id} - {status}</p><p onClick={() => {setModalVisibility(!modalVisibility); setBoxID(id)}} className={"link"}>Update Status {'>'}</p></div>);
    }
  } 

  return (
    <div className="third-card">
      <div className="card-header" onClick={() => {setExpanded(!expanded)}}>
        <FiChevronDown size={26} style={{marginRight: 5}} />
        <h3>{props.type}</h3>
        {card_indicator}
      </div>      
      <div className="box-stats-cont">
        <p className="box-stat">Rtrnd: {props.boxData["returned"].length}</p>
        <p className="box-stat">Dmgd:  {props.boxData["damaged"].length}</p>
        <p className="box-stat">Rdy:  {props.boxData["ready"].length}</p>
        <p className="box-stat">Dsptchd:  {props.boxData["dispatched"].length}</p>
        <p className="box-stat">Instit:  {props.boxData["institution"].length}</p>
      </div>
      <div hidden={!expanded}>
        {boxes}
      </div>
      <div hidden={!modalVisibility}>
        <UpdateBoxModal closeModal={() => setModalVisibility(!modalVisibility)} updatePage={() => props.updatePage()} boxID={boxID} />
      </div>
    </div>
  );
}

export default BoxCard;