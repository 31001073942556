import React, { useState, useEffect} from 'react';
import '../App.css';
import Navbar from '../components/organisms/navbar';
import AvaliabilityByBoxModule from '../components/organisms/avaliabilityByBoxModule';
import UpdateBoxButtons from '../components/molecules/updateBoxButtons';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../App';

function ShippingDashboard() {
  const [boxData, setBoxData] = useState({"Success": true, "SortedBoxes": {}, "AllocatableBoxes": {}});
  const navigate = useNavigate();

  // Function to fetch orders data
  function getStockData() {
    const url = SERVER_URL + "/stock_management/dumpallstock";

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.token,
      },
    })
        .then((res) => {
          const data:any = res.json();
          
          // If request is successful
          if (res.status === 200) {
            return data;
          
          // If token has expired, redirect to the login page
          } else if (res.status === 401) {
            navigate("/");

          // If an error occurs, log it to console
          } else {
            console.log(data["error"]["message"]);
          }
        })
        .then((data) => {
            // Update the box data, to render the columns
            setBoxData(data);
        });
  }

  // Collect data on page load
  useEffect(() => {
    getStockData();
  }, []); 
  
  return (
    <div className="app-container">
      <Navbar />
      <div className='full-column-cont'>
        <UpdateBoxButtons updatePage={() => {getStockData()}}/>
      </div>
      <div className="columns-container">
        <div className="full-column">
          <AvaliabilityByBoxModule boxData={boxData} updatePage={() => getStockData()}/>
        </div>
      </div>
    </div>
  );
}

export default ShippingDashboard;