import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import Navbar from "../components/organisms/navbar";
import AwaitingDispatchModule from "../components/organisms/awaitingDispatchModule";
import AwaitingReturnModule from "../components/organisms/awaitingReturnModule";
import WithCustomersModule from "../components/organisms/withCustomersModule";
import OrdersButtons from "../components/molecules/ordersButtons";
import { SERVER_URL } from "../App";
import DownloadButton from "../components/atoms/DownloadCSV";
import DownloadEvri from "../components/atoms/DownloadEvriBulk";

function OrdersDashboard() {
  const [boxData, setBoxData] = useState({
    AwaitingDispatch: [],
    AwaitingReturn: [],
    WithCustomers: [],
    Errored: [],
  });
  const navigate = useNavigate();

  // Function to fetch orders data
  function getOrdersData() {
    const url = SERVER_URL + "/stock_management/dumpallorders";

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((res) => {
        const data: any = res.json();

        // If request is successful
        if (res.status === 200) {
          return data;

          // If token has expired, redirect to the login page
        } else if (res.status === 401) {
          navigate("/");

          // If an error occurs, log it to console
        } else {
          console.log(data);
        }
      })
      .then((data) => {
        // Update the box data, to render the columns
        setBoxData(data);
        // console.log(data["AwaitingDispatch"]);
      }).catch((error) => {
        console.log(error)
      });
  }

  // Collect data on page load
  useEffect(() => {
    getOrdersData();
  }, []);

  return (
    <div className="app-container">
      <Navbar />
      <div className="full-column-cont">
        <OrdersButtons
          updatePage={() => getOrdersData()}
          erroredOrders={boxData["Errored"]}
        />
      </div>
      <div className="columns-container">
        <div className="third-column">
          <h2>Awaiting Dispatch - {boxData["AwaitingDispatch"].length}</h2>
          <p style={{ textAlign: "center" }}>
            Boxes that have been paid for, that are marked as needing
            dispatched.
          </p>
            <DownloadEvri
              data={boxData["AwaitingDispatch"]}
              filename={`customers_awaiting_dispatch`}
            />
          <AwaitingDispatchModule
            boxData={boxData["AwaitingDispatch"]}
            updatePage={() => getOrdersData()}
          />
        </div>
        <div className="third-column">
          <h2>With Customers - {boxData["WithCustomers"].length}</h2>
          <p style={{ textAlign: "center" }}>
            Boxes with customers, that have no required action.
          </p>
          <WithCustomersModule
            boxData={boxData["WithCustomers"]}
            updatePage={() => getOrdersData()}
          />
        </div>
        <div className="third-column">
          <h2>Awaiting Return - {boxData["AwaitingReturn"].length}</h2>
          <p style={{ textAlign: "center" }}>
            Boxes with customers, that have been notfiied to return.
          </p>
          <AwaitingReturnModule
            boxData={boxData["AwaitingReturn"]}
            updatePage={() => getOrdersData()}
          />
        </div>
      </div>
    </div>
  );
}

export default OrdersDashboard;
