import React, { useState } from 'react'; 
import { RiCheckboxMultipleLine, RiRefreshLine, RiErrorWarningLine } from 'react-icons/ri';
import ReturnOrderByIDModal from "../atoms/returnOrderByIDModal";
import ErroredOrdersModal from "./erroredOrdersModal"

interface OrdersButtonsProps {
  updatePage: Function,
  erroredOrders: any,
}

function OrdersButtons(props:OrdersButtonsProps) {
  const [returnModalVisibility, setReturnModalVisibility] = useState(false)
  const [erroredModalVisibility, setErroredModalVisibility] = useState(false)

  return (
    <div className='button-card-cont'>
      <div className="pill-button yellow" onClick={() => setErroredModalVisibility(!erroredModalVisibility)}>
        <RiErrorWarningLine size={18}/>
        <h3>Errored Orders - {props.erroredOrders.length}</h3>
      </div>
      <div className="pill-button stack" onClick={() => setReturnModalVisibility(!returnModalVisibility)}>
        <RiCheckboxMultipleLine size={18}/>
        <h3>Return By ID</h3>
      </div>
      <div className="pill-button stack" onClick={() => props.updatePage()}>
        <RiRefreshLine size={18}/>
        <h3>Refresh</h3>
      </div>
      <div hidden={!returnModalVisibility}>
        <ReturnOrderByIDModal email={"SKIP"} boxType={"SKIP"} closeModal={() => setReturnModalVisibility(false)} updatePage={props.updatePage}/>
      </div>
      <div hidden={!erroredModalVisibility}>
        <ErroredOrdersModal erroredOrders={props.erroredOrders} closeModal={() => setErroredModalVisibility(false)} updatePage={props.updatePage}/>
      </div>
    </div>
  );
}

export default OrdersButtons;