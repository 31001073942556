import React from "react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ReturnModal from "../atoms/returnOrderModal";
import ReshipBoxModal from "../atoms/reshipOrderModal";

interface WithCustomersCardProps {
  boxType: string;
  parentsName: string;
  returnDays: string;
  email: string;
  updatePage: Function;
  boxID: string;
  orderNumber: string;
  dataLog: any;
}

function WithCustomersCard(props: WithCustomersCardProps) {
  const [expanded, setExpanded] = useState(false);
  const [returnModalVisibility, setReturnModalVisibility] = useState(false);
  const [reshipModalVisibility, setReshipModalVisibility] = useState(false);

  let card_indicator;
  if (parseInt(props.returnDays) >= 1) {
    card_indicator = (
      <div className="card-indicator green">
        Notified in {props.returnDays} days
      </div>
    );
  } else if (parseInt(props.returnDays) === 0) {
    card_indicator = <div className="card-indicator amber">Notified Today</div>;
  } else if (parseInt(props.returnDays) < 0) {
    card_indicator = (
      <div className="card-indicator red">
        {Math.abs(parseInt(props.returnDays))} Days Overdue
      </div>
    );
  }

  return (
    <div className="card-container">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <FiChevronDown size={26} style={{ marginRight: 5 }} />
        <h3>
          {props.boxType} (id: {props.boxID})
        </h3>
        {card_indicator}
      </div>
      <div className="card-body" hidden={!expanded}>
        <p>Ref Number: {props.orderNumber}</p>
        <p>Parent's Name: {props.parentsName}</p>
        <p>Email: {props.email}</p>
        <p>
          Tutorial Status:{" "}
          {"APP_TUTORIALCOMPLETED" in props.dataLog
            ? "Complete ✅"
            : "Incomplete ❌"}
        </p>
        <p>
          Extensions Opened:{" "}
          {"APP_EXTENSIONOPENED_1" in props.dataLog ? "1, " : ""}
          {"APP_EXTENSIONOPENED_2" in props.dataLog ? "2, " : ""}
          {"APP_EXTENSIONOPENED_3" in props.dataLog ? "3, " : ""}
          {"APP_EXTENSIONOPENED_4" in props.dataLog ? "4, " : ""}
        </p>
        
        <div className="button-row">
          <div
            className="button red left"
            onClick={() => setReshipModalVisibility(true)}
          >
            Reship
          </div>
          <div
            className="button left"
            onClick={() => setReturnModalVisibility(true)}
          >
            Returned
          </div>
        </div>
      </div>
      <div hidden={!returnModalVisibility}>
        <ReturnModal
          email={props.email}
          boxType={props.boxType}
          closeModal={() => setReturnModalVisibility(false)}
          updatePage={() => props.updatePage()}
          boxID={props.boxID}
        />
      </div>
      <div hidden={!reshipModalVisibility}>
        <ReshipBoxModal
          email={props.email}
          boxType={props.boxType}
          closeModal={() => setReshipModalVisibility(false)}
          updatePage={() => props.updatePage()}
          boxID={props.boxID}
        />
      </div>
    </div>
  );
}

export default WithCustomersCard;
