import React from "react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ReturnOrderModal from "../atoms/returnOrderModal";
import LostBoxModal from "../atoms/lostBoxModal";
import { useNavigate } from "react-router";
import { SERVER_URL } from "../../App";

interface PrePurchaseCardProps {
  firstName: string;
  lastName: string;
  childName: string;
  age: number;
  phone: string;
  email: string;
  address: string;
  postcode: string;
  status: boolean;
  paymentDate: string;
  createdDate: string;
  numBoxesOrdered: number;
  referralCodeUsed: string;
  remindersSent: string[];
}

function PrePurchaseCard(props: PrePurchaseCardProps) {
  const [expanded, setExpanded] = useState(false);
  const [emailResent, setEmailResent] = useState(false);
  const [emailResentText, setEmailResentText] = useState("Resend signup email");
  const [copyLinkDisabled, setCopyLinkDisabled] = useState(false);
  const [copyLinkText, setCopyLinkText] = useState("Copy signup link");
  const [errormessage, setErrormessage] = useState("");
  const [returnModalVisibility, setReturnModalVisibility] = useState(false);
  const [lostModalVisibility, setLostModalVisibility] = useState(false);
  const navigate = useNavigate();

  const orderStatusChoices = [
    "created",
    "abandoned",
    "paid",
    "cancellation_requested",
    "refunded",
  ];

  function daysSincePastTZString(pastTZString: string) {
    const pastDate = new Date(Date.parse(pastTZString));
    const today = new Date(Date.now());
    const timeDiff = today.getTime() - pastDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  }

  const daysSincePayment = daysSincePastTZString(props.paymentDate);

  const card_indicator = (
    <div
      className={`card-indicator ${
        props.status && daysSincePayment < 3
          ? "green"
          : props.status
          ? "red"
          : "amber"
      }`}
    >
      {props.status
        ? `paid - ${daysSincePayment} day${daysSincePayment > 1 ? "s" : ""} ago`
        : "unpaid"}
    </div>
  );

  function resendEmail() {
    setEmailResent(true);
    setEmailResentText("Loading...");

    const url = SERVER_URL + "/stock_management/resendusersetupemail";

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        user_email: props.email,
      }),
    })
      .then((res) => {
        const data: any = res.json();

        // If request is successful
        if (res.status === 200) {
          setEmailResentText("Email sent ✅");
          // If token has expired, redirect to the login page
        } else if (res.status === 401) {
          navigate("/");

          // If any other error occurs
        } else {
          throw data;
        }
      })
      .catch((data) => {
        setErrormessage(data["error"]["message"]);
        setEmailResent(false);
        setEmailResentText("Resend signup email");
      });
  }

  function getSignupLink() {
    setCopyLinkDisabled(true);
    setCopyLinkText("Loading...");

    const url = SERVER_URL + "/stock_management/getusersignuplink";

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        user_email: props.email,
      }),
    })
      .then(async (res) => {
        const data: any = await res.json();

        // If request is successful
        if (res.status === 200) {
          const url = data.url;
          navigator.clipboard.writeText(url);
          setCopyLinkText("Copied ✅");
          setTimeout(() => {
            setCopyLinkDisabled(false);
            setCopyLinkText("Copy signup link");
          }, 5000);

          // If token has expired, redirect to the login page
        } else if (res.status === 401) {
          navigate("/");

          // If any other error occurs
        } else {
          throw data;
        }
      })
      .catch((data) => {
        setErrormessage(data["error"]["message"]);
        setCopyLinkDisabled(false);
        setCopyLinkText("Copy signup link");
      });
  }

  return (
    <div className="card-container">
      <div className="card-header" onClick={() => setExpanded(!expanded)}>
        <FiChevronDown size={26} style={{ marginRight: 5 }} />
        <h3>{props.email}</h3>
        {card_indicator}
      </div>
      <div className="card-body" hidden={!expanded}>
        <p>First Name: {props.firstName}</p>
        <p>Last Name: {props.lastName}</p>
        <p>Child's Name: {props.childName}</p>
        <p>Age: {props.age}</p>
        <p>Phone: {props.phone}</p>
        <p>Email: {props.email}</p>
        <p>Address: {props.address.replaceAll("$", ", ")}</p>
        <p>Postcode: {props.postcode}</p>
        <p>Status: {props.status}</p>
        <p>Created Date: {new Date(props.createdDate).toLocaleString()}</p>
        {props.status ? (
          <p>Payment Date: {new Date(props.paymentDate).toLocaleString()}</p>
        ) : null}
        <p>Number of Boxes Ordered: {props.numBoxesOrdered}</p>
        {props.status ? <p>Reminders Sent: </p> : null}
        {props.status ? (
          props.remindersSent.length === 0 ? (
            <ul>
              <li>
                <em>No reminders sent.</em>
              </li>
            </ul>
          ) : (
            <ol>
              {props.remindersSent.map((reminder) => {
                return <li>{new Date(reminder).toLocaleString()}</li>;
              })}
            </ol>
          )
        ) : null}
        {props.status ? (
          <div className="button-row">
            <button
              className="button"
              disabled={copyLinkDisabled}
              style={{ width: 144 }}
              onClick={() => getSignupLink()}
            >
              {copyLinkText}
            </button>
            <button
              className="button left"
              disabled={emailResent}
              style={{ width: 174 }}
              onClick={() => resendEmail()}
            >
              {emailResentText}
            </button>
          </div>
        ) : null}
        {errormessage ? (
          <p style={{ color: "red", fontSize: 12, textAlign: "right" }}>
            {errormessage}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default PrePurchaseCard;
