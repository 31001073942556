import React from "react";
import AwaitingReturnCard from "../molecules/awaitingReturnCard";

interface AwaitingReturnProps {
  boxData: any;
  updatePage: Function;
}

function AwaitingReturnModule(props: AwaitingReturnProps) {
  let cards = [];
  for (const userInstance of props.boxData) {
    cards.push(<AwaitingReturnCard updatePage={() => props.updatePage()} boxType={userInstance.boxType} parentsName={userInstance.parentsName} orderNumber={userInstance.orderNumber} returnDays={userInstance.daysUntilReturn} email={userInstance.email} boxID={userInstance.boxID} dataLog={userInstance.dataLog} phone={userInstance.phone} />);
  } 

  return <div className="card-list-container">{cards}</div>;
}

export default AwaitingReturnModule;
