import React, { useState } from 'react'; 
import { CgClose } from 'react-icons/cg';
import ErroredOrderCard from '../atoms/erroredOrderCard';

interface DispatchModalProps {
  erroredOrders: any,
  closeModal: Function,
  updatePage: Function,
}

function ErroredOrdersModal(props:DispatchModalProps) {

  let cards = [];
  for (const userInstance of props.erroredOrders) {
    cards.push(<ErroredOrderCard updatePage={() => props.updatePage()} boxType={userInstance.boxType} parentsName={userInstance.parentsName} dispatchDays={userInstance.daysUntilDispatch} address={userInstance.address} postcode={userInstance.postcode} email={userInstance.email} phone={userInstance.phone} childsName={userInstance.childsName} boxID={userInstance.boxID}/>);
  } 

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>Errored Orders</h2>
          <CgClose size={26} style={{marginRight: 5}} onClick={() => {props.closeModal()}} />
        </div>
        {cards}
      </div>
    </div>
  );
}

export default ErroredOrdersModal;