import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { SERVER_URL } from '../App';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();
  
  function verifyAdmin(token:string) {
    const url = SERVER_URL + "/stock_management/verifyadminstatus";

    fetch(url, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    })
        .then((res) => {
          const data:any = res.json();

          // If request is successful
          if (res.status === 200) {
            return [true, data];
          } else if (res.status === 401) {
            navigate("/");
            return [false, {}]
          } else {
            setErrorMessage(data["error"]["message"]);
            return [false, {}]
          }
        })
        .then((data) => {
          if (data[0]) {
            localStorage.setItem('token', token);
            navigate("/orders")
          }
        });
  }
  
  function getToken() {
    const url = SERVER_URL + "/auth/login/";

    fetch(url, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: email,
        password: password,
      })
    })
        .then((res) => {
          const data:any = res.json();
          return data
        })
        .then((data) => {
          if (data.hasOwnProperty('access')) {
            verifyAdmin(data["access"]);
          } else {
            setErrorMessage(data["detail"]);
          }
        })
  }
  
  return (
    <div className="login-cont">
      <h2>Team Repair Admin</h2>
      <input
        type="text"
        name="CNAME"
        placeholder="Email"
        id="mce-CNAME"
        className="text-input"
        onChange={(event) => setEmail(event.target.value)}
        value={email}
      />
      <input
        type="password"
        name="CNAME"
        placeholder="Password"
        id="mce-CNAME"
        className="text-input"
        onChange={(event) => setPassword(event.target.value)}
        value={password}
      />
      <div className="button" onClick={() => getToken()}>
        Login
      </div>
      <div className="modal-footer">
        <p className="error-txt" style={{textAlign: "center"}}>{errorMessage}</p>
      </div>
    </div>
  );
}

export default Login;