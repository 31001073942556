import React, { FC } from "react";
import { CgClose } from "react-icons/cg";
import { RiBookOpenLine, RiCheckboxMultipleLine } from "react-icons/ri";

interface UserDataRendererProps {
  hidden: boolean;
  json: {
    users_child_details: {
      child_name: string;
      child_age: Number;
      icon: string;
      shipments: any[];
      current_box_index: number;
      logbooks: any[];
    };
    users_guardian_details: {
      postcode: string;
      address: string;
      phone: string;
      referral_code: string;
    };
    users_instance: {
      email: string;
      password: string;
      first_name: string;
      last_name: string;
      date_joined: string;
    };
  };
}

function JsonModal(props: {
  data: any;
  modalVisibility: boolean;
  closeModal: Function;
}) {
  return props.modalVisibility ? (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>Shipment Datalog</h2>
          <CgClose
            size={26}
            style={{ marginRight: 5 }}
            onClick={() => {
              props.closeModal();
            }}
          />
        </div>
        <textarea
          rows={20}
          defaultValue={JSON.stringify(props.data, null, 2)}
        />
      </div>
    </div>
  ) : null;
}

function UserDataRenderer(props: UserDataRendererProps) {
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  try {
    return (
      <div hidden={!props.hidden} style={{ width: "100%" }}>
        <h1>
          {props.json.users_instance["first_name"] +
            " " +
            props.json.users_instance["last_name"]}
        </h1>
        <table>
          <tbody>
            <tr>
              <td>
                Child Name: {props.json.users_child_details["child_name"]} |
              </td>
              <td>
                Age at Join:{" "}
                {props.json.users_child_details["child_age"].toString()} |
              </td>
              <td>Phone: {props.json.users_guardian_details["phone"]}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                Date Joined:{" "}
                {props.json.users_instance["date_joined"].slice(0, 10)} |
              </td>
              <td>
                Referral Code:{" "}
                {props.json.users_guardian_details["referral_code"]}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tr>
            <td>Address: {props.json.users_guardian_details["address"].replaceAll("$", ", ")} |</td>
            <td>Postcode: {props.json.users_guardian_details["postcode"]}</td>
          </tr>
        </table>
        <br />
        <JsonModal
          data={modalData}
          closeModal={() => setModalVisibility(false)}
          modalVisibility={modalVisibility}
        />
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Plan</th>
              <th>Status</th>
              <th>Expected Dispatch</th>
              <th>Actual Dispatch</th>
              <th>Expected Return</th>
              <th>Actual Return</th>
              <th>Box ID</th>
              <th>Unlock</th>
            </tr>
          </thead>
          <tbody>
            {props.json.users_child_details["shipments"].map(
              (shipment, index) => {
                return (
                  <tr key={index}>
                    <td>{shipment[0]}</td>
                    <td>{shipment[1]}</td>
                    <td>{shipment[2]}</td>
                    <td>{shipment[3].slice(0, 10)}</td>
                    <td>{shipment[4].slice(0, 10)}</td>
                    <td>{shipment[5].slice(0, 10)}</td>
                    <td>{shipment[6].slice(0, 10)}</td>
                    <td>{shipment[7]}</td>
                    <td>{shipment[8]}</td>
                    <td>
                      <div
                        className="pill-button yellow"
                        onClick={() => {
                          setModalVisibility(true);
                          setModalData(JSON.parse(shipment[9]));
                        }}
                      >
                        <RiBookOpenLine size={18} />
                        <h3>Log</h3>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    );
  } catch {
    return <div hidden={!props.hidden}></div>;
  }
}

export default UserDataRenderer;
