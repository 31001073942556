import React from "react";
import PrePurchaseCard from "../molecules/prePurchaseCard";

interface PrePurchaseProps {
  data: any;
  updatePage: Function;
}

function PrePurchaseModule(props: PrePurchaseProps) {
  let cards = [];
  for (const userAccountInfo of props.data) {
    cards.push(
      <PrePurchaseCard
        firstName={userAccountInfo["first_name"]}
        lastName={userAccountInfo["last_name"]}
        childName={userAccountInfo["child_name"]}
        age={userAccountInfo["child_age"]}
        phone={userAccountInfo["phone"]}
        email={userAccountInfo["email"]}
        address={userAccountInfo["address"]}
        postcode={userAccountInfo["postcode"]}
        status={userAccountInfo["payment_status"]}
        paymentDate={userAccountInfo["payment_date"]}
        createdDate={userAccountInfo["created_date"]}
        numBoxesOrdered={userAccountInfo["num_boxes_ordered"]}
        referralCodeUsed={userAccountInfo["referral_code"]}
        remindersSent={userAccountInfo["reminders_sent"]}
      />
    );
  }

  return <div className="card-list-container">{cards}</div>;
}

export default PrePurchaseModule;
