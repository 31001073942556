import React from 'react'; 
import AwaitingDispatchCard from '../molecules/awaitingDispatchCard';

interface AwaitingDispatchProps {
  boxData: any
  updatePage: Function
}

function AwaitingDispatchModule(props:AwaitingDispatchProps) {
  let cards = [];
  for (const userInstance of props.boxData) {
    cards.push(<AwaitingDispatchCard updatePage={() => props.updatePage()} boxType={userInstance.boxType} orderNumber={userInstance.orderNumber} parentsName={userInstance.parentsName} dispatchDays={userInstance.daysUntilDispatch} address={userInstance.address} postcode={userInstance.postcode} email={userInstance.email} phone={userInstance.phone} childsName={userInstance.childsName} referralCode={userInstance.referralCode} />);
  } 

  return (
    <div className="card-list-container">
      {cards}
    </div>
  );
}

export default AwaitingDispatchModule;