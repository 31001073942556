import React from "react";

interface Data {
  [key: string]: string;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const downloadEvriBulk = (data: Data[], filename: string): void => {
  const csv =
    "Address_line_1,Address_line_2,Address_line_3,Address_line_4,Postcode,First_name,Last_name,Email,Weight(Kg),Compensation(£),Signature(y/n),Reference,Contents,Parcel_value(£),Delivery_phone,Delivery_safe_place,Delivery_instructions,Service\n" +
    data
      .map((customer) => {
        const newAddress = customer.address
          .replaceAll("None,", "")
          .replaceAll("GB", "");
        let addArray;
        if (newAddress.includes("$")) {
          addArray = newAddress.split("$");
        } else {
          addArray = newAddress.split(", ");
        }
        let address;
        if (addArray.length === 5) {
          address =
            addArray[0] +
            addArray[1] +
            "," +
            addArray[2] +
            "," +
            addArray[3] +
            "," +
            addArray[4];
        } else if (addArray.length === 4) {
          address =
            addArray[0] +
            "," +
            addArray[1] +
            "," +
            addArray[2] +
            "," +
            addArray[3];
        } else {
          address = addArray[0] + "," + addArray[1] + "," + addArray[2] + ",";
        }

        return `${address},${customer.postcode.toUpperCase()},${capitalizeFirstLetter(
          customer.firstName
        )},${capitalizeFirstLetter(customer.lastName)},${
          customer.email
        },0.18,20,N,${customer.orderNumber},Team Repair Kit,28,${
          customer.phone + String.fromCharCode(8203)
        },,Please only leave in a safe place if it's sheltered and dry,\nSouth Park Studios,South Park,Sevenoaks,,TN131AN,Megan,Hale,megan@team.repair,0.18,20,N,${
          customer.orderNumber
        },Team Repair Kit,28,7909062088,Through letter box,,`;
      })
      .join("\n");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("type", "text/csv");
  link.href = url;
  const dt = new Date().toISOString();
  link.setAttribute("download", `${filename}_${dt}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

function DownloadEvri(props: { data: Data[]; filename: string }) {
  const handleDownload = (): void => {
    downloadEvriBulk(props.data, props.filename);
  };

  return (
    <button
      className="pill-button outline"
      style={{ margin: 0, marginTop: 8 }}
      onClick={handleDownload}
    >
      Download Evri CSV
    </button>
  );
}

export default DownloadEvri;
